import React from "react"
import { graphql } from "gatsby"

// Hooks & Querys
import { ProductFragment } from "../GraphQl/queryFragments" // eslint-disable-line

// Import components
import SEO from "../components/global/seo"
import { BannerProduct, BlockZone } from "../components/pageBlocks"

const Product = ({ data }) => {
  const {
    heading,
    metaDescription,
    keywords,
    pageBlocks,
    shortDescription,
    featuredImage,
  } = data.sanityProduct || {}
  return (
    <>
      <BannerProduct
        heading={heading}
        description={shortDescription}
        image={featuredImage}
      />
      <SEO title={heading} keywords={keywords} description={metaDescription} />
      <BlockZone blocks={pageBlocks} />
    </>
  )
}

export default Product

export const pageQuery = graphql`
  query productQuery($slug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      ...ProductFields
    }
  }
`
